import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import aApp from 'store/app/actions';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { CreateCampaignDto, ReadCampaignDto } from '@goparrot/campaign-sdk';
import { campaignFacade } from '../../api/campaignApi';

export const useSelectedCampaign = (): {
  selected: ReadCampaignDto;
  isFetching: boolean;
  getSelected: (merchantId: string, id: string) => void;
  createCampaign: (merchantId: string, data: CreateCampaignDto) => void;
  updateCampaign: (merchantId: string, id: string, data: ReadCampaignDto) => void;
  startCampaign: (merchantId: string, id: string, data: ReadCampaignDto) => void;
  completeCampaign: (merchantId: string, id: string) => void;
  resetSelected: () => void;
} => {
  const [selected, setSelected] = useState<ReadCampaignDto | undefined>();
  const [isFetching, setFetching] = useState(false);
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const { notifyError, notifySuccess } = useNotifications();

  const getSelected = useCallback(
    async (merchantId: any, id: any) => {
      setFetching(true);
      try {
        const result = await campaignFacade.get(merchantId, id);
        if (result) {
          setSelected(result);
        }
      } catch (errors) {
        console.error(errors);
        notifyError({ text: getIntlString('notification.campaign.get.campaign.error.description') });
      } finally {
        setFetching(false);
      }
    },
    [reduxDispatch],
  );

  const updateCampaign = useCallback(
    async (merchantId: any, id: any, data: any) => {
      setFetching(true);
      try {
        const result = await campaignFacade.update(merchantId, id, data);
        if (result) {
          setSelected(result);
        }
        notifySuccess({ text: getIntlString('notification.campaign.update.success.description') });
      } catch (errors) {
        const { message, statusCode }: { message: string[] | string; statusCode: number } = errors?.response?.data;
        if (400 === statusCode) {
          reduxDispatch(aApp.pushCampaignNotificationWithError({ message }));
        } else {
          notifyError({ text: getIntlString('notification.campaign.update.error.description') });
        }
      } finally {
        setFetching(false);
      }
    },
    [reduxDispatch],
  );

  const createCampaign = useCallback(
    async (merchantId: any, data: any) => {
      setFetching(true);
      try {
        const result = await campaignFacade.create(merchantId, data);
        if (result) {
          setSelected(result);
        }
        history.push(`/dashboard/campaigns/${result.uuid}`);
        notifySuccess({ text: getIntlString('notification.campaign.create.success.description') });
      } catch (errors) {
        const { message, statusCode }: { message: string[] | string; statusCode: number } = errors?.response?.data;
        if (400 === statusCode) {
          reduxDispatch(aApp.pushCampaignNotificationWithError({ message }));
        } else {
          notifyError({ text: getIntlString('notification.campaign.create.error.description') });
        }
      } finally {
        setFetching(false);
      }
    },
    [history, reduxDispatch],
  );

  const startCampaign = useCallback(
    async (merchantId: any, id: any, data: any) => {
      setFetching(true);
      try {
        const response: ReadCampaignDto = await ('new' === id ? campaignFacade.create(merchantId, data) : campaignFacade.update(merchantId, id, data));
        history.push(`/dashboard/campaigns/${response.uuid}`);
        const result = await campaignFacade.start(merchantId, response.uuid);
        if (result) {
          setSelected(result);
        }
        notifySuccess({ text: getIntlString('notification.campaign.start.success.description') });
      } catch (errors) {
        const { message, statusCode }: { message: string[]; statusCode: number } = errors?.response?.data;
        if (400 === statusCode) {
          reduxDispatch(aApp.pushCampaignNotificationWithError({ message }));
        } else {
          notifyError({ text: getIntlString('notification.campaign.start.error.description') });
        }
      } finally {
        setFetching(false);
      }
    },
    [history, reduxDispatch],
  );

  const completeCampaign = useCallback(
    async (merchantId: any, id: any) => {
      setFetching(true);
      try {
        const result = await campaignFacade.complete(merchantId, id);
        if (result) {
          setSelected(result);
        }
        notifySuccess({ text: getIntlString('notification.campaign.end.success.description') });
      } catch (e) {
        notifyError({ text: getIntlString('notification.campaign.end.error.description') });
      } finally {
        setFetching(false);
      }
    },
    [reduxDispatch],
  );

  const resetSelected = useCallback(() => {
    setSelected(null);
  }, []);

  return { selected, isFetching, getSelected, updateCampaign, createCampaign, startCampaign, completeCampaign, resetSelected };
};
