import * as React from 'react';
import { FilterSettingsType, FiltersRef } from '@goparrot-dashboard/shared-utils';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MarketHeader } from '@market/react-bindings';
import { TitleHelpBlock } from '@goparrot-dashboard/shared-ui/src/components/title-help-block';
import { Filters } from '../../../../shared-utils/src/components/filters/Filters';
import { Search } from '../../../../shared-utils/src/components/search/Search';
import { ActionButton, Actions } from '../Actions';

type Props = {
  title: string;
  helpText?: string;
  helpUrl?: string;
  actions?: ActionButton[];
  filters?: FilterSettingsType[];
  defaultFilters?: FilterSettingsType[];
  setFilters?: (filters: FilterSettingsType[]) => void;
  searchQuery: string;
  setSearchQuery?: React.Dispatch<React.SetStateAction<string>>;
  searchPlaceholder?: string;
  itemsCount: number | null | undefined;
};

export const ItemListHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  helpText,
  helpUrl,
  actions,
  filters,
  setFilters,
  searchQuery,
  setSearchQuery,
  searchPlaceholder,
  itemsCount,
  defaultFilters,
}) => {
  const [filterRefs, setFiltersRefs] = React.useState<FiltersRef>({});
  const closeAllFilters = React.useCallback(() => {
    // close all filters when focusing on search
    Object.keys(filterRefs).forEach((key) => {
      filterRefs[key]?.current?.(undefined);
    });
  }, [filterRefs]);

  return (
    <div className="tw-space-y-4">
      <MarketHeader>
        <h2>
          {title}
          {itemsCount !== null && <span className="tw-text-blue-gray-400 tw-w-4 tw-ml-2">{itemsCount}</span>}
        </h2>
        <Actions actions={actions} />
        <TitleHelpBlock text={helpText} url={helpUrl} />
      </MarketHeader>
      <section className="tw-ml-0 tw-flex tw-flex-wrap tw-gap-2">
        <div onFocus={closeAllFilters} className="tw-w-60 tw-h-12">
          <Search value={searchQuery} onSearch={setSearchQuery} placeholder={searchPlaceholder || getIntlString('search.placeholder')}></Search>
        </div>
        {filters && <Filters setFiltersRefs={setFiltersRefs} filters={filters} setFilters={setFilters} defaultFilters={defaultFilters} />}
      </section>
    </div>
  );
};
