import { TagEnum } from '@goparrot/storeitems-sdk';
import { getFranchisePermissions, getStoreFranchiseConfig, FranchisePermissions } from '@goparrot-dashboard/orchestration';
import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { ReadLocationDto } from '@goparrot/menu-orchestrator-sdk';
import { hasPermission, IReadRoleV2Dto, PermissionsV2 } from '@goparrot/users-v2-sdk';
import { Item } from '../interface';

type GetFranchisePermissionsResult = FranchisePermissions | null;

export type GetFranchisePermissionsParams = {
  storeId: string;
  franchiseLocations: ReadLocationDto[];
  isFranchise: boolean;
  userRole?: IReadRoleV2Dto;
};

type AceessToCentralLocationParams = {
  userStores: string[] | null | undefined;
  stores: ReadStoreDto[];
  centralLocationId: string | undefined;
  isVirtualMenu: boolean;
  roleV2?: IReadRoleV2Dto;
};

export const FRANCHISE_MERCHANT_PERMISSIONS = {
  isCentralLocation: false,
  canEditConnections: true,
  canEditLabels: true,
  canEditArchived: true,
  canEditAvailability: true,
  canEditOrderable: true,
  canEditPrice: true,
  canAddNewItems: true,
  canEditItems: true,
  hasNoPermissions: false,
  canEditSku: true,
} as const;

export const getMenuFranchisePermissions = ({
  franchiseLocations,
  storeId,
  isFranchise,
  userRole,
}: GetFranchisePermissionsParams): GetFranchisePermissionsResult => {
  if (!isFranchise) return null;

  const canEditFranchise = hasPermission(userRole, PermissionsV2.FRANCHISE_MANAGEMENT.MENU_WRITE);

  const franchiseBasedPermissions = getFranchisePermissions(getStoreFranchiseConfig(franchiseLocations, storeId));

  if (franchiseBasedPermissions?.isCentralLocation) return null;

  if (canEditFranchise) {
    return FRANCHISE_MERCHANT_PERMISSIONS;
  }

  return franchiseBasedPermissions;
};

export const hasFranchiseTag = (item?: Item): boolean => {
  return (item?.tags ?? []).includes(TagEnum.FRANCHISE);
};

export const checkHasAccessToCentralLocation = ({ userStores, stores, centralLocationId, isVirtualMenu, roleV2 }: AceessToCentralLocationParams): boolean => {
  if (isVirtualMenu) {
    return hasPermission(roleV2, PermissionsV2.MERCHANT.NAVBAR_VIEW);
  }

  if (userStores) {
    return userStores.includes(centralLocationId);
  }
  return !!stores?.find((store) => store.storeId === centralLocationId);
};

export const getFranchisePermissionForItem = ({
  item,
  key,
  isMerchant,
  franchiseLocations,
  franchisePermissions,
  userRole,
}: {
  item: Item;
  key: keyof FranchisePermissions;
  isMerchant: boolean;
  franchiseLocations: ReadLocationDto[];
  franchisePermissions: GetFranchisePermissionsResult;
  userRole: IReadRoleV2Dto;
}): boolean => {
  if (isMerchant) {
    const canEditFranchise = hasPermission(userRole, PermissionsV2.FRANCHISE_MANAGEMENT.MENU_WRITE);
    const itemFranchisePermissions = getFranchisePermissions(getStoreFranchiseConfig(franchiseLocations, item.storeId));
    return canEditFranchise || !!itemFranchisePermissions?.[key];
  }

  return !!(franchisePermissions && franchisePermissions[key]);
};
