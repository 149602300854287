import * as React from 'react';
import { useFormFieldProps, useCheckboxProps } from '@goparrot-dashboard/entity-form';
import { FormField, Toggle } from '@goparrot-dashboard/shared-ui';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { FranchisePermissions } from '@goparrot-dashboard/orchestration';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { getMenuFranchisePermissions, hasFranchiseTag } from '../../utils';
import { Item } from '../../interface';
import { useMenuFormContext } from '../../context';

type Props<T> = {
  field: keyof T;
  formLabel: string | React.ReactNode;
  checkedLabel?: string | React.ReactNode;
  uncheckedLabel?: string | React.ReactNode;
  franchiseEditPermissionField: keyof Pick<FranchisePermissions, 'canEditAvailability' | 'canEditArchived' | 'canEditOrderable'>;
};

export const ItemToggle = <T extends Record<string, boolean>>({
  field,
  formLabel,
  checkedLabel,
  uncheckedLabel,
  franchiseEditPermissionField,
}: Props<T>): React.ReactElement | null => {
  const { form, query, isFranchise, franchisePermissions, isMerchant } = useMenuFormContext<T, Item>();
  const { franchiseLocations } = useStoreService();
  const { roleV2 } = useUserService();

  const bindFormFieldProps = useFormFieldProps(form);
  const bindCheckedProps = useCheckboxProps(form);
  const isItemReplica = isFranchise && hasFranchiseTag(query?.data);
  const toggleProps = bindCheckedProps(field);
  const hasFranchisePermission = isMerchant
    ? getMenuFranchisePermissions({ isFranchise, storeId: query?.data?.storeId, franchiseLocations, userRole: roleV2 })?.[franchiseEditPermissionField]
    : franchisePermissions?.[franchiseEditPermissionField];
  const isCanEditOrderable = franchiseEditPermissionField === 'canEditOrderable';

  return (
    <FormField
      {...bindFormFieldProps(field)}
      label={formLabel}
      input={
        <Toggle
          {...toggleProps}
          disabled={isItemReplica ? (isCanEditOrderable ? hasFranchisePermission : !hasFranchisePermission) : toggleProps.disabled}
          uncheckedLabel={uncheckedLabel}
          checkedLabel={checkedLabel}
        />
      }
    />
  );
};
