import { useEffect, useState, useCallback } from 'react';
import { ReadConfigurationDto } from '@goparrot/notifier-sdk';

import { notifierConfigurationFacade } from '../../api/notifierConfigurationApi';

export const useGetNotifierConfiguration = (
  merchantId: string,
): {
  configuration: ReadConfigurationDto;
  fetchConfiguration: boolean;
  refetchConfiguration: () => void;
} => {
  const [configuration, setConfiguration] = useState<ReadConfigurationDto>();
  const [fetchConfiguration, setFetchConfiguration] = useState<boolean>(false);
  const refetchConfiguration = useCallback(async () => {
    try {
      setFetchConfiguration(true);
      const config = await notifierConfigurationFacade.get(merchantId);
      if (config) {
        setConfiguration(config);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFetchConfiguration(false);
    }
  }, [merchantId]);

  useEffect(() => {
    if (merchantId) {
      refetchConfiguration();
    }
  }, [merchantId, refetchConfiguration]);

  return { configuration, fetchConfiguration, refetchConfiguration };
};
