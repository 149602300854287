import React, { FC } from 'react';
import {
  ReadCategoryDto,
  ReadItemBaseGroupDto,
  ReadItemBaseDto as ItemBaseModel,
  ReadItemOptionsDto as ItemOptionsModel,
  ReadStoreItemDto as StoreItemModel,
  ReadStoreItemGroupDto,
} from '@goparrot/storeitems-sdk';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { FranchisePermissions } from '@goparrot-dashboard/orchestration';
import { Toggle, Tooltip } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { hasPermission, PermissionsV2 } from '@goparrot/users-v2-sdk';
import { findMod, getFranchisePermissionForItem, hasFranchiseTag, isMerchantLevel } from '../utils';

type OriginalType = ItemOptionsModel | ItemBaseModel | ReadCategoryDto | StoreItemModel | ReadStoreItemGroupDto | ReadItemBaseGroupDto;

type IsOrderableToggleProps = {
  original: OriginalType;
  value: boolean;
  franchisePermissions: FranchisePermissions | null;
  onUpdate(value: Partial<OriginalType>, item: OriginalType): void;
  isStoreOperationsRole: boolean;
  customDisableToggle?: boolean;
};

export const IsOrderableToggle: FC<React.PropsWithChildren<IsOrderableToggleProps>> = ({
  value,
  original,
  franchisePermissions,
  onUpdate,
  isStoreOperationsRole,
  customDisableToggle = false,
}) => {
  const { isMerchant, selectedStoreId, isFranchise, franchiseLocations } = useStoreService();
  const { roleV2 } = useUserService();
  const { getIntlString } = useI18n();
  const isMerchantLevelItem = isMerchantLevel(original);
  const isModsMode = !isMerchant && isMerchantLevelItem;
  const isDisabledOnMerchant = isModsMode && !value;
  const isDisabledForOperations = isStoreOperationsRole && !isMerchantLevelItem;
  const isStoreAdmin = hasPermission(roleV2, PermissionsV2.STORE_MENU_MANAGEMENT.API_UPDATE); // ref FSCATAMGMT-1105
  const isDisabledByFranchise =
    isFranchise &&
    hasFranchiseTag(original) &&
    !isStoreAdmin &&
    !getFranchisePermissionForItem({
      item: original,
      key: 'canEditOrderable',
      isMerchant,
      franchiseLocations,
      franchisePermissions,
      userRole: roleV2,
    });

  const isDisabled = isDisabledByFranchise || isDisabledOnMerchant || isDisabledForOperations || customDisableToggle;
  const checked = isModsMode ? findMod(selectedStoreId, original.mods)?.isOrderable : value;

  return (
    <Tooltip tooltip={isDisabledOnMerchant ? getIntlString('page.merchant.menu.table.disabledOnMerchant.tooltip', { isRootPath: true }) : null}>
      <div className="tw-h-4.5">
        <Toggle isCritical={true} checked={checked ?? value} onChange={(isOrderable) => onUpdate({ isOrderable }, original)} disabled={isDisabled} />
      </div>
    </Tooltip>
  );
};
