import { useI18n } from '@goparrot-dashboard/i18n';
import classNames from 'classnames';
import React from 'react';
import { generatePath } from 'react-router';
import { NavLink } from 'react-router-dom';
import { LocationItem, MerchantItem } from '../../interface';
import { FRANCHISE_MANAGEMENT, MULTI_BRAND_MANAGEMENT } from '../constants';

type SidebarItemProps = {
  item: MerchantItem | LocationItem;
  onClick(navLinkName: string): void;
  storeId: string;
  isMenuSettingsPageEnabled?: boolean;
};

export const SidebarItem: React.FC<SidebarItemProps> = ({ item, onClick, storeId, isMenuSettingsPageEnabled }) => {
  const { getIntlString } = useI18n();
  const isFranchiseOrMultiBrand = [FRANCHISE_MANAGEMENT, MULTI_BRAND_MANAGEMENT].includes(item.label);
  const handleNavLinkClick = () => onClick(item.label);
  return (
    <NavLink
      data-testid={`${getIntlString(item.label, { isRootPath: true }).replace(/ /g, '-').toLowerCase()}-sidebar-item`}
      key={item.label}
      exact={item.exact}
      activeClassName="tw-text-blue-500 tw-bg-blue-100"
      className={classNames([
        'tw-flex tw-items-start tw-px-1.5 tw-py-3 tw-whitespace-normal',
        'tw-text-black tw-leading-4 lg:hover:tw-text-blue-500 lg:hover:tw-opacity-100',
        'tw-transform paragraph-20 tw-rounded-lg',
      ])}
      to={generatePath(item.link, { id: storeId })}
      onClick={handleNavLinkClick}
    >
      {item.icon && <span className={'tw-flex tw-mr-2 tw-pt-0.5 tw-w-4 tw-h-4'}>{item.icon}</span>}
      <span
        className={classNames([
          isFranchiseOrMultiBrand && 'tw-max-w-min',
          'tw-inline-block tw-leading-5 semibold-30  lg:hover:tw-text-blue-500 lg:hover:tw-opacity-100',
        ])}
      >
        {isMenuSettingsPageEnabled && item.label === 'sidebar.settings'
          ? getIntlString('sidebar.giftCard.settings', { isRootPath: true })
          : getIntlString(item.label, { isRootPath: true })}
      </span>
    </NavLink>
  );
};
