import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { IReadRoleV2Dto } from '@goparrot/users-v2-sdk';
import { MERCHANT_IMPERSONATE, MERCHANT_OPERATOR } from '../constants';
import { Item } from '../interface';

export const filterByRole = <T extends Item>(items: T[] = [], role: IReadRoleV2Dto, stores: ReadStoreDto[]) => {
  const isCorrectRole = role.uuid === MERCHANT_OPERATOR || role.uuid === MERCHANT_IMPERSONATE;

  if (isCorrectRole) {
    return items.filter((item) => {
      const allStoresId = stores.map((item) => item.storeId);
      return allStoresId.includes(item.storeId);
    });
  } else {
    return items;
  }
};
