import { FormatNumberOptions } from 'react-intl';

export const CAMPAIGN_API_PATH = 'campaign';
export const DATE_FORMAT = 'D MMMM';
export const DEFAULT_WORKFLOW_TIME = '8:00';

export const FORMAT_TIME_12H = 'h:mm A';
export const FORMAT_TIME_24H = 'HH:mm';

export const NOTIFIER_TEST_EMAIL_FIELDS = ['title', 'channel', 'config'] as const;
export const NOTIFIER_EMAIL_FIELDS = [...NOTIFIER_TEST_EMAIL_FIELDS, 'storeId'] as const;
export const NOTIFIER_CONFIG_FIELDS = ['subject', 'body', 'unsubscribe', 'replyTo', 'fromName', 'from', 'unsubscribeText'] as const;
export const BASE_PATH = 'page.merchant.campaigns';
export const FILTER_TEMPLATE_PROPS = ['title', 'storeId', 'channel', 'config', 'uuid', 'merchantId'];
export const FILTER_TEMPLATE_CONFIG_PROPS = ['subject', 'body', 'uri', 'unsubscribe', 'replyTo', 'fromName', 'from'];
export const PROMOTION_FIELDS_TO_REMOVE = ['merchantId', 'uuid', 'createdAt', 'updatedAt'];

export enum REQUEST_STATUSES {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export const DEFAULT_INTL_NUMBER_FORMAT_OPTIONS: FormatNumberOptions = {
  style: 'percent',
  maximumFractionDigits: 2,
};

export const DEFAULT_ID = '_DEFAULT_';
