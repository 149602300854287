import { hasPermission, PermissionsV2 } from '@goparrot/users-v2-sdk';
import React from 'react';
import { Spinner } from '@goparrot-dashboard/shared-ui';
import { Redirect, Switch } from 'react-router-dom';
import {
  GRAFANA_REPORT_PATH_PREFIX,
  isDefaultStore as isStoreDefault,
  LOYALTY_MODULE_READ_PERMISSIONS,
  PROMOTION_MODULE_READ_PERMISSIONS,
  ReportTagEnum,
} from '@goparrot-dashboard/shared-utils';
import { MultiEntityTypeEnum } from '@goparrot/common';
import { useLaunchDarkly } from '../../hooks';
import { addDDRumContext } from '../../datadog';
import { AppWrapper } from '../../components/containers/app';
import { RouterProps } from '../types';
import * as component from '../loadable-modules';
import { RoleRoute as Route } from './RoleRoute';

export const PrivateRoutes: React.FC<React.PropsWithChildren<RouterProps>> = ({
  authenticated,
  authenticating,
  featuresFlags = {},
  merchant,
  store,
  merchantId,
  locations,
  locationsFetching,
  isMerchant,
  initialLocationsFetchMade,
  roleV2,
  user,
}) => {
  useLaunchDarkly({ user, isMerchant, merchant });

  React.useEffect(() => {
    addDDRumContext(user?.uuid, merchantId);
  }, [user, merchantId]);

  if (!authenticated) {
    return authenticating ? <Spinner isMarketComponent /> : <Redirect to="/auth" />;
  }

  const isDefaultStore = isStoreDefault(store);
  const hasNoLocations = isMerchant && initialLocationsFetchMade && !locationsFetching && locations?.length === 0;
  const isGoParrotSuperAdmin = hasPermission(roleV2, PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN);

  return (
    <AppWrapper>
      <React.Suspense fallback={<Spinner isMarketComponent />}>
        {!merchantId ? (
          <Switch>
            <Route path="/dashboard/merchant/setup" render={() => <component.MerchantWizardModule />} />
            <Route path="*" render={() => <Redirect to="/dashboard/merchant/setup" />} />
          </Switch>
        ) : (
          <Switch>
            {/* ****************MERCHANT LEVEL************************ */}
            <Route path="/dashboard/merchant/setup" render={() => <component.MerchantWizardModule />} />
            <Route
              key="locations"
              allowed={[[PermissionsV2.MERCHANT.LOCATIONS_PAGE_VIEW, PermissionsV2.STORE.API_READ]]}
              restricted={[PermissionsV2.ROLE_PERMISSION.STORE_OPERATIONS]}
              path="/dashboard/locations"
              component={() => <component.LocationsModule />}
            />
            {hasNoLocations && !isGoParrotSuperAdmin && <Route path="*" render={() => <Redirect to="/dashboard/merchant/setup/locations" />} />}
            <Route exact key="merchant-home" path="/dashboard" allowed={[PermissionsV2.MERCHANT.HOME_PAGE_VIEW]} render={() => <component.HomePageModule />} />
            <Route
              allowed={[[PermissionsV2.MERCHANT.SETTINGS_PAGE_VIEW, PermissionsV2.MERCHANT.API_READ]]}
              path="/dashboard/merchant"
              render={() => <component.MerchantModule />}
            />
            <Route
              allowed={[PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN, PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN]}
              path="/dashboard/merchant/domain"
              render={() => <component.DomainModule />}
            />
            <Route
              exact
              allowed={[[PermissionsV2.MERCHANT.FEATURES_SETUP_PAGE_VIEW, PermissionsV2.MERCHANT.API_UPDATE]]}
              path="/dashboard/features-setup"
              render={() => <component.MerchantFlagsModule />}
            />
            <Route
              key="location-group"
              allowed={[[PermissionsV2.MERCHANT.LOCATIONS_PAGE_VIEW, PermissionsV2.STORE.API_READ]]}
              restricted={[PermissionsV2.ROLE_PERMISSION.STORE_OPERATIONS]}
              path="/dashboard/location-group"
              component={() => <component.LocationGroupModule />}
            />
            <Route
              key="cohort"
              allowed={[PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN, PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN, PermissionsV2.COHORT.WEB_READ]}
              path="/dashboard/audiences"
              render={() => <component.CohortModule />}
            />
            <Route
              key="campaign-module"
              allowed={[
                PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
                PermissionsV2.CAMPAIGN.WEB_READ,
              ]}
              path="/dashboard/campaigns"
              render={() => <component.CampaignModule />}
            />
            <Route
              key="campaign-module"
              disabled={!featuresFlags.isFranchiseMarketingEnabled}
              allowed={[PermissionsV2.MERCHANT.API_READ]}
              path="/dashboard/sq-campaigns"
              render={() => <component.FranchiseMarketingModule />}
            />
            <Route
              key="user-list"
              allowed={[[PermissionsV2.MERCHANT.TEAMS_PAGE_VIEW, PermissionsV2.USER_V2.API_READ]]}
              path="/dashboard/users"
              render={() => <component.UsersModule />}
            />
            <Route
              key="merchant-reports"
              allowed={[PermissionsV2.MERCHANT.REPORTS_PAGE_VIEW]}
              path="/dashboard/reports/:categoryId?/:reportName?"
              render={() => <component.ReportsModule reportTag={ReportTagEnum.MAIN} />}
            />
            <Route
              key="promotions-module"
              allowed={[[...PROMOTION_MODULE_READ_PERMISSIONS, PermissionsV2.MERCHANT.PROMOTION_PAGE_VIEW]]}
              path="/dashboard/promotions"
              render={() => <component.PromotionModule />}
            />
            <Route
              exact
              key="merchant-orders"
              allowed={[[PermissionsV2.MERCHANT_ORDER.API_READ, PermissionsV2.MERCHANT_ORDER.API_UPDATE]]}
              path="/dashboard/orders"
              component={() => <component.OrdersModule />}
            />
            <Route
              key="menu"
              allowed={[
                PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.STORE_ADMIN,
                PermissionsV2.ROLE_PERMISSION.STORE_OPERATIONS,

                PermissionsV2.STORE_MENU_MANAGEMENT.WEB_CREATE,
                PermissionsV2.STORE_MENU_MANAGEMENT.WEB_READ,
                PermissionsV2.STORE_MENU_MANAGEMENT.WEB_UPDATE,
                PermissionsV2.STORE_MENU_MANAGEMENT.WEB_DELETE,

                PermissionsV2.MERCHANT_MENU_MANAGEMENT.WEB_CREATE,
                PermissionsV2.MERCHANT_MENU_MANAGEMENT.WEB_READ,
                PermissionsV2.MERCHANT_MENU_MANAGEMENT.WEB_UPDATE,
                PermissionsV2.MERCHANT_MENU_MANAGEMENT.WEB_DELETE,
              ]}
              path="/dashboard/menu-management/:id?/:itemType?/:itemStoreId?/:uniqueName?/:storeItemType?/:variationId?"
              render={(props) => <component.MenuManagementRootPage {...props} />}
            />
            <Route
              key="loyalty-module"
              allowed={[LOYALTY_MODULE_READ_PERMISSIONS]}
              path="/dashboard/loyalty-programs"
              render={() => <component.LoyaltyModule />}
            />
            <Route
              key="customer"
              allowed={[[PermissionsV2.CUSTOMER.API_READ, PermissionsV2.MERCHANT.CUSTOMER_PAGE_VIEW]]}
              path="/dashboard/customers"
              render={() => <component.CustomersModule />}
            />
            <Route
              disabled={!(MultiEntityTypeEnum.FOOD_HALL === featuresFlags.multiEntityType)}
              key="multi-brand-management"
              allowed={[PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN, PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN]}
              path="/dashboard/multi-brand"
              render={() => <component.MultiBrandModule />}
            />
            <Route
              disabled={MultiEntityTypeEnum.FRANCHISE !== featuresFlags.multiEntityType}
              key="franchise-management"
              allowed={[
                PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,

                PermissionsV2.FRANCHISE_MANAGEMENT.WEB_CREATE,
                PermissionsV2.FRANCHISE_MANAGEMENT.WEB_READ,
                PermissionsV2.FRANCHISE_MANAGEMENT.WEB_UPDATE,
                PermissionsV2.FRANCHISE_MANAGEMENT.WEB_DELETE,
              ]}
              path="/dashboard/franchise/:tab?"
              render={() => <component.FranchiseModule />}
            />
            <Route
              disabled={!(MultiEntityTypeEnum.FRANCHISE === featuresFlags.multiEntityType && merchant.shortTermFeatureFlags?.isMenuSettingsPageEnabled)}
              key="franchise-menu-settings"
              allowed={[
                PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,

                PermissionsV2.FRANCHISE_MANAGEMENT.WEB_CREATE,
                PermissionsV2.FRANCHISE_MANAGEMENT.WEB_READ,
                PermissionsV2.FRANCHISE_MANAGEMENT.WEB_UPDATE,
                PermissionsV2.FRANCHISE_MANAGEMENT.WEB_DELETE,
              ]}
              path="/dashboard/franchise-menu"
              render={() => <component.FranchiseMenuSettingsModule />}
            />
            <Route
              key="franchise-reports"
              allowed={[
                PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
                PermissionsV2.FRANCHISE_REPORTS.WEB_READ,
              ]}
              path={`${GRAFANA_REPORT_PATH_PREFIX}/:categoryId?/:reportName?`}
              render={() => <component.ReportsModule reportTag={ReportTagEnum.FRANCHISE} />}
            />
            <Route key="gift-card" allowed={[PermissionsV2.GIFT_CARDS.API_READ]} path="/dashboard/gift-cards" render={() => <component.GiftCardModule />} />
            <Route
              key="franchise-directory"
              disabled={MultiEntityTypeEnum.FRANCHISE !== featuresFlags.multiEntityType || !featuresFlags.isFranchiseDirectoryEnabled}
              path="/dashboard/sq-directory"
              render={() => <component.FranchiseDirectoryModule />}
            />
            {/* /**********************************************************/
            /*********************STORE LEVEL**************************/}
            <Route exact key="home" path="/dashboard/:id/home" allowed={[PermissionsV2.STORE.HOME_PAGE_VIEW]} render={() => <component.HomePageModule />} />
            <Route
              key="general"
              allowed={[[PermissionsV2.STORE.GENERAL_SETTINGS_PAGE_VIEW, PermissionsV2.STORE.API_READ]]}
              path="/dashboard/:id/general"
              render={() => <component.LocationsModule />}
            />
            <Route
              key="employees-location-list"
              allowed={[PermissionsV2.STORE.TEAMS_PAGE_VIEW, PermissionsV2.USER_V2.API_READ]}
              path="/dashboard/:id/users"
              render={() => <component.UsersModule />}
            />
            <Route
              exact
              key="print-tags-location"
              allowed={[[PermissionsV2.STORE.PRINT_TAGS_PAGE_VIEW, PermissionsV2.VENDOR.API_READ, PermissionsV2.STORE.API_READ]]}
              path="/dashboard/:id/print-tags"
              render={() => <component.StorePrintableTagModule />}
            />
            <Route
              key="business-hours"
              allowed={[[PermissionsV2.STORE.BUSINESS_HOURS_PAGE_VIEW, PermissionsV2.STORE.API_READ]]}
              path="/dashboard/:id/business-hours"
              render={() => <component.BusinessHours />}
            />
            <Route
              disabled={!!isDefaultStore}
              key="dining-options"
              path="/dashboard/:id/dining-options"
              allowed={[[PermissionsV2.STORE_DINING_OPTIONS.API_READ, PermissionsV2.STORE.API_READ]]}
              component={() => <component.DiningOptions />}
            />
            <Route
              exact
              key="pickup"
              path="/dashboard/:id/pickup"
              allowed={[
                PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.STORE_ADMIN,
              ]}
              component={() => <component.Pickup />}
            />
            <Route
              key="delivery-settings-module"
              path="/dashboard/:id/delivery"
              allowed={[
                PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.STORE_ADMIN,
              ]}
              render={() => <component.DeliveryModule />}
            />
            <Route
              exact
              key="orders"
              path="/dashboard/:id/orders"
              allowed={[[PermissionsV2.STORE_ORDER.API_READ, PermissionsV2.STORE_ORDER.API_UPDATE]]}
              component={() => <component.OrdersModule />}
            />
            <Route
              key="orders-throttling"
              allowed={[
                [
                  PermissionsV2.STORE_ORDER.THROTTLING_PAGE_VIEW,
                  PermissionsV2.STORE_ORDER_CONFIG.API_READ,
                  PermissionsV2.STORE_ORDER_CONFIG.API_CREATE,
                  PermissionsV2.STORE_ORDER_CONFIG.API_UPDATE,
                ],
              ]}
              path="/dashboard/:id/orders-throttling"
              component={() => <component.OrdersThrottlingModule />}
            />
            <Route
              key="location-reports"
              allowed={[PermissionsV2.STORE_REPORTS.PAGE_VIEW]}
              path="/dashboard/:storeId/reports/:categoryId?/:reportName?"
              render={() => <component.ReportsModule reportTag={ReportTagEnum.MAIN} />}
            />
            <Route
              exact
              key="service-charges-module"
              allowed={[
                [
                  PermissionsV2.STORE_SERVICE_CHARGES.API_READ,
                  PermissionsV2.VENDOR.API_READ,
                  PermissionsV2.STORE.API_READ,
                  PermissionsV2.STORE_MENU_MANAGEMENT.API_READ,
                ],
              ]}
              path="/dashboard/:id/service-charges"
              component={() => <component.ServiceChargesModule mountPath="/dashboard/:id/service-charges" />}
            />
            <Route
              allowed={[
                PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.STORE_ADMIN,
              ]}
              path="/dashboard/:id/notifications"
              render={() => <component.NotificationsModule />}
            />
            <Route
              allowed={[[PermissionsV2.STORE.POS_SETTINGS_PAGE_VIEW, PermissionsV2.VENDOR.API_READ]]}
              path="/dashboard/:id/pos"
              render={() => <component.VendorModule />}
            />
            <Route
              allowed={[
                PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
                PermissionsV2.ROLE_PERMISSION.STORE_ADMIN,

                PermissionsV2.STORE_PAYMENT.WEB_CREATE,
                PermissionsV2.STORE_PAYMENT.WEB_READ,
                PermissionsV2.STORE_PAYMENT.WEB_UPDATE,
                PermissionsV2.STORE_PAYMENT.WEB_DELETE,
              ]}
              path="/dashboard/:id/payment"
              render={() => <component.PaymentModule />}
            />
            <Route
              allowed={[
                [
                  PermissionsV2.STORE.PREPARATION_TIME_PAGE_VIEW,
                  PermissionsV2.VENDOR.API_READ,
                  PermissionsV2.STORE.API_READ,
                  PermissionsV2.STORE_ORDER_CONFIG.API_READ,
                ],
              ]}
              path="/dashboard/:id/order-config"
              render={() => <component.OrderConfigModule />}
            />
            <Route
              key="promotions-module"
              allowed={[
                [...PROMOTION_MODULE_READ_PERMISSIONS, PermissionsV2.MERCHANT.PROMOTION_PAGE_VIEW],
                [...PROMOTION_MODULE_READ_PERMISSIONS, PermissionsV2.STORE_PROMOTION.PAGE_VIEW],
              ]}
              path="/dashboard/:id/promotions"
              render={() => <component.PromotionModule />}
            />
            {/* /**********************************************************/}
          </Switch>
        )}
      </React.Suspense>
    </AppWrapper>
  );
};
