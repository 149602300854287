import { useState, useCallback, useEffect } from 'react';
import { TagCountDto } from '@goparrot/core-sdk/platform/user';
import { userFacade } from '@goparrot-dashboard-api/customers';

export const useGetCountByTags = (
  merchantId: string,
): {
  tags: TagCountDto[];
  fetchTags: boolean;
  refetchTags: () => void;
} => {
  const [tags, setTags] = useState<TagCountDto[]>([]);
  const [fetchTags, setFetchTags] = useState<boolean>(false);
  const refetchTags = useCallback(async () => {
    setFetchTags(true);
    try {
      setFetchTags(true);
      const data = await userFacade.getCountByTags(merchantId);
      setTags(data);
    } catch (e) {
      console.error(e);
    }
    setFetchTags(false);
  }, [merchantId]);

  useEffect(() => {
    if (merchantId) {
      refetchTags();
    }
  }, [merchantId, refetchTags]);

  return { tags, fetchTags, refetchTags };
};
