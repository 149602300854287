import { LocationItem, Menu, MerchantItem } from '../../interface';
import { isTopLevelItem } from './isTopLevelItem';

export function getNavBarItems(category: Menu<MerchantItem | LocationItem> | MerchantItem | LocationItem): (MerchantItem | LocationItem)[] {
  if (isTopLevelItem(category)) {
    return [category];
  } else {
    return category.items;
  }
}
