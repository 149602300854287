import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useNotifications, DialogStateReturn } from '@goparrot-dashboard/shared-ui';
import { I18nService, useI18n } from '@goparrot-dashboard/i18n';
import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { BatchPatchStoreItemDto } from '@goparrot/storeitems-sdk';
import { diff, Search, Filters, FilterSettingsType, IVirtualFilterParamsType, useDialogState } from '@goparrot-dashboard/shared-utils';
import { useLocationGroupList } from '@goparrot-dashboard-api/location-group';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useMerchantBatchPatchItems } from '@goparrot-dashboard-api/menu-management';
import { MarketModalFull } from '@market/react-bindings';
import { StoreItemTypeEnum } from '@goparrot/storeitems-sdk';
import { useLocation } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import { UseQueryResult } from 'react-query';
import { LOCATION_GROUPS_FILTER } from '../../constants';
import { filterListByStoreTitle, getFormattedFilters, replaceLocation } from '../../utils';
import { useLocationGroupsMultipleRequests } from '../../hooks';
import { SettingsModalHeader } from './SettingsModalHeader';
import { ReplicasTableActions } from './ReplicasTableActions';
import { ActionsModal } from './ActionsModal';
import {
  ActionData,
  ACTIONS_VALUES,
  ORDERABILITY_VALUES,
  ReplicaUpdateDataFields,
  SELECTED_MODIFIER_PARAM,
  SHOW_STORE_SPECIFIC_MODAL_PARAM,
  StoreSpecificItem,
} from './constants';
import { CloseWithoutSavingModal } from './CloseWithoutSavingModal';
import { TableReplicasWithLocationGroups } from './TableReplicasWithLocationGroups';
import { LocationGroupItem, LocationGroupWithReplicasType, isLocationGroupItem, mapLocationGroupsWithReplicas, sortLocationGroupsWithReplicas } from './utils';

type Props = {
  dialog: DialogStateReturn;
  selectedItem: StoreSpecificItem;
  itemType: StoreItemTypeEnum;
  replicasListQuery: UseQueryResult<StoreSpecificItem[]>;
  hideTaxColumn?: boolean;
};
const LOCATION_GROUP_TAKE = 100;
export type ReplicasUpdateData = Record<string, StoreSpecificItem>;

const StoreSpecificDisplaySettingsModalComponent: React.FC<React.PropsWithChildren<Props>> = ({
  dialog,
  selectedItem,
  itemType,
  replicasListQuery,
  hideTaxColumn = false,
}) => {
  const { getIntlString } = useI18n();
  const { notifyError, notifySuccess } = useNotifications();
  const {
    stores,
    merchant: { merchantId },
  } = useStoreService();
  const [allSelectedState, setAllSelectedState] = useState<boolean | 'indeterminate'>(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [modalLoaded, setModalLoaded] = useState(false);
  const [selectedReplicas, setSelectedReplicas] = useState<StoreSpecificItem[]>([]);
  const replicasUpdateData = useRef<ReplicasUpdateData>({});
  const updateCount = useRef<number>(0);
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const priceValue = React.useMemo(() => selectedReplicas.map((item) => item.price)[0], [selectedReplicas]);
  const haveSamePrice = React.useMemo(() => selectedReplicas.every((item) => item.price === selectedReplicas[0].price), [selectedReplicas]);

  const ACTION_DATA_INITIAL_STATE: ActionData = useMemo(
    () => ({
      action: null,
      price: haveSamePrice || selectedReplicas.length <= 1 ? priceValue : selectedItem.price,
      title: selectedItem.title,
      isArchived: selectedItem.isArchived,
      isOrderable: selectedItem.isOrderable ? ORDERABILITY_VALUES.ORDERABLE : ORDERABILITY_VALUES.NON_ORDERABLE,
    }),
    [haveSamePrice, priceValue, selectedItem.isArchived, selectedItem.isOrderable, selectedItem.price, selectedItem.title, selectedReplicas.length],
  );

  const [actionData, setActionData] = useState<ActionData>(ACTION_DATA_INITIAL_STATE);
  const actionDialog = useDialogState();
  const leavePageModal = useDialogState();

  const hideSettingsDialog = useCallback(() => {
    dialog.dismiss();
    setModalLoaded(false);
    searchParams.delete(SHOW_STORE_SPECIFIC_MODAL_PARAM);
    searchParams.delete(SELECTED_MODIFIER_PARAM);
    const newSearchParams = `?${searchParams.toString()}`;
    replaceLocation({ searchParams: newSearchParams, pathname: location.pathname });
  }, [dialog, location.pathname, searchParams]);

  const closeModal = () => {
    if (updateCount.current) {
      leavePageModal.show();
    } else {
      hideSettingsDialog();
    }
  };

  useEffect(() => {
    if (actionData.action) {
      actionDialog.show();
    } else {
      actionDialog.dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData]);

  useEffect(() => {
    if (!actionDialog.visible) {
      setActionData(ACTION_DATA_INITIAL_STATE);
    }
  }, [ACTION_DATA_INITIAL_STATE, actionDialog.visible]);

  const [skip, setSkip] = useState(0);

  const locationGroupListQuery = useLocationGroupList(
    merchantId,
    { offset: skip, limit: LOCATION_GROUP_TAKE },
    {
      onError: () => {
        notifyError({
          text: getIntlString('page.location-group.notification.list.error.description', { isRootPath: true }),
        });
      },
      enabled: false,
      retry: 2,
    },
  );

  const { items, hasRemainingItems } = useLocationGroupsMultipleRequests({
    query: locationGroupListQuery,
    setSkip,
    enabled: dialog.visible,
    take: LOCATION_GROUP_TAKE,
  });

  const replicasIds = useMemo(() => {
    return (replicasListQuery.data || []).map((item) => item.uniqueName);
  }, [replicasListQuery.data]);

  const allReplicasSelected = useMemo(() => {
    if (replicasListQuery.data && replicasListQuery.data.length) {
      const selectedIds = selectedReplicas.map((item) => item.uniqueName);
      return replicasIds.every((el) => selectedIds.includes(el));
    }
    return false;
  }, [replicasIds, replicasListQuery.data, selectedReplicas]);

  useEffect(() => {
    if (allReplicasSelected) {
      setAllSelectedState(true);
    } else if (selectedReplicas.length) {
      setAllSelectedState('indeterminate');
    } else {
      setAllSelectedState(false);
    }
  }, [allReplicasSelected, selectedReplicas.length]);

  const filteredReplicasList: StoreSpecificItem[] = React.useMemo(() => filterListByStoreTitle(replicasListQuery.data, searchQuery, stores), [
    replicasListQuery.data,
    searchQuery,
    stores,
  ]);

  const selectAllOption = useCallback(() => {
    if (!replicasIds.length) {
      return;
    }
    if (selectedReplicas.length) {
      setSelectedReplicas([]);
      return;
    }
    setSelectedReplicas(filteredReplicasList);
  }, [filteredReplicasList, replicasIds.length, selectedReplicas.length]);

  const toggleSelectReplica = useCallback(
    (replica: StoreSpecificItem) => {
      if (selectedReplicas.some((item) => item.uniqueName === replica.uniqueName)) {
        const newSelectedReplicas = selectedReplicas.filter((item) => item.uniqueName !== replica.uniqueName);
        return setSelectedReplicas(newSelectedReplicas);
      }
      return setSelectedReplicas([...selectedReplicas, replica]);
    },
    [selectedReplicas],
  );
  const toggleSelectAllReplica = (replicas: StoreSpecificItem[]) => {
    setSelectedReplicas((prevState) => {
      const hasItemToRemove = prevState.some((element) => replicas.includes(element));
      if (hasItemToRemove) {
        return prevState.filter((el) => !replicas.includes(el));
      } else {
        return [...prevState, ...replicas];
      }
    });
  };

  const replicasInitialData = React.useMemo(() => {
    return (replicasListQuery.data || []).reduce((result, item) => {
      result[item.uniqueName] = item;
      return result;
    }, {});
  }, [replicasListQuery.data]);

  useEffect(() => {
    replicasUpdateData.current = replicasInitialData;
    updateCount.current = 0;
  }, [replicasInitialData]);

  const handleUpdateReplicaData = ({ uniqueName, data }: { uniqueName: string; data: ReplicaUpdateDataFields }) => {
    const newData = { ...replicasUpdateData.current, [uniqueName]: { ...replicasUpdateData.current[uniqueName], ...data } as StoreSpecificItem };
    replicasUpdateData.current = newData;
    const changes = diff(replicasUpdateData.current, replicasInitialData);
    updateCount.current = Object.keys(changes).filter((key) => !isEmpty(changes[key])).length;
  };

  const locationGroupsWithReplicas = useMemo(() => {
    return mapLocationGroupsWithReplicas({ locationGroupList: items, filteredReplicasList });
  }, [filteredReplicasList, items]);

  const locationGroupsWithReplicasSorted = useMemo(() => sortLocationGroupsWithReplicas(locationGroupsWithReplicas), [locationGroupsWithReplicas]);

  const defaultFilters = useMemo(
    () => [
      {
        ...LOCATION_GROUPS_FILTER,
        items: [
          ...locationGroupsWithReplicasSorted.map((item: ReadStoreDto & LocationGroupWithReplicasType) => {
            const name = stores.find((store) => store.storeId === item.storeId)?.name;
            const lastItemOfLocationGroup = locationGroupsWithReplicasSorted
              .filter((item: ReadStoreDto & LocationGroupWithReplicasType) => item.locationGroupData)
              .map((item: ReadStoreDto & LocationGroupWithReplicasType) => item.locationGroupData.uuid)
              .pop();

            if (name) {
              return { value: item.storeId, label: name };
            }
            return {
              value: item.locationGroupData?.uuid,
              label: item.locationGroupData?.name,
              hasSeparator: lastItemOfLocationGroup === item.locationGroupData?.uuid ?? false,
            };
          }),
        ],
      },
    ],
    [locationGroupsWithReplicasSorted, stores],
  );

  const [filters, setFilters] = React.useState<FilterSettingsType[]>(defaultFilters);
  useEffect(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  const formattedFilters = useMemo(() => {
    return getFormattedFilters(filters);
  }, [filters]);

  const locationGroupsWithReplicasFiltered = (formattedFilters as IVirtualFilterParamsType).isLocationGroup?.length
    ? locationGroupsWithReplicasSorted.filter((item: ReadStoreDto & LocationGroupWithReplicasType) =>
        (formattedFilters as IVirtualFilterParamsType)?.isLocationGroup?.includes(isLocationGroupItem(item) ? item.locationGroupData?.uuid : item.storeId),
      )
    : locationGroupsWithReplicasSorted;

  const batchUpdateMutation = useMerchantBatchPatchItems({
    onSuccess: () => {
      notifySuccess({
        text: getIntlString('update.success'),
      });
      replicasListQuery.refetch();
    },
    onError: () =>
      notifyError({
        text: getIntlString('update.error'),
      }),
    onSettled: () => {
      if (actionDialog.visible) {
        actionDialog.dismiss();
      }
    },
  });

  // On update button click
  const onSubmit = useCallback(() => {
    const changes = diff(replicasUpdateData.current, replicasInitialData);
    const mappedChanges = Object.keys(changes).map((uniqueName) => ({ uniqueName, ...changes[uniqueName] }));
    batchUpdateMutation.mutate({ merchantId, data: { [itemType]: mappedChanges } });
  }, [batchUpdateMutation, merchantId, replicasInitialData, itemType]);

  // Update from actions modal
  const handleUpdate = () => {
    const itemsToUpdate: BatchPatchStoreItemDto[] = selectedReplicas.map((replica) => ({
      uniqueName: replica.uniqueName,
      ...(actionData.action === ACTIONS_VALUES.DELETE && { isArchived: true }),
      ...(actionData.action === ACTIONS_VALUES.REVERT_SETTINGS && { isArchived: actionData.isArchived }),
      ...([ACTIONS_VALUES.UPDATE_PRICE, ACTIONS_VALUES.REVERT_SETTINGS].includes(actionData.action) && { price: actionData.price }),
      ...([ACTIONS_VALUES.UPDATE_TITLE, ACTIONS_VALUES.REVERT_SETTINGS].includes(actionData.action) && { title: actionData.title }),
      ...([ACTIONS_VALUES.UPDATE_ORDERABLE, ACTIONS_VALUES.REVERT_SETTINGS].includes(actionData.action) && {
        isOrderable: actionData.isOrderable === ORDERABILITY_VALUES.ORDERABLE,
      }),
    }));
    batchUpdateMutation.mutate({ merchantId, data: { [itemType]: itemsToUpdate } });
  };

  // Update by deleting/restoring replica from table
  const toggleReplicaArchived = (replica: StoreSpecificItem) => {
    batchUpdateMutation.mutate({ merchantId, data: { [itemType]: [{ uniqueName: replica.uniqueName, isArchived: !replica.isArchived }] } });
  };

  return (
    <>
      <MarketModalFull
        onMarketDialogLoaded={() => {
          setModalLoaded(true);
        }}
        className="tw-z-52"
        onMarketDialogDismissed={hideSettingsDialog}
        layout="regular"
      >
        <div className="tw-w-screen tw-h-screen tw-overflow-hidden">
          <SettingsModalHeader closeModal={closeModal} updateCount={updateCount} onSubmit={onSubmit} />
          <div className="tw-w-full tw-py-6">
            <h1 className="tw-text-2xl tw-text-blue-gray-900 tw-font-bold tw-break-all tw-max-w-lg tw-m-0">{selectedItem.title}</h1>
            <div className="tw-flex tw-items-center tw-space-x-3 tw-py-8">
              <div className="tw-w-72">
                <Search value={searchQuery} onSearch={setSearchQuery} placeholder={getIntlString('search')} />
              </div>
              <Filters filters={filters} setFilters={setFilters} hideClearAllButton />
              <ReplicasTableActions
                selectAction={(action: ACTIONS_VALUES) => {
                  if (ACTIONS_VALUES.REVERT_SETTINGS === action) {
                    // this fixes a bug when selecting 1 location and then choosing revert settings option, the price was incorrect
                    setActionData({ ...actionData, action, price: selectedItem.price });
                  } else {
                    setActionData({ ...actionData, action });
                  }
                }}
                disabled={!selectedReplicas.length || replicasListQuery.isFetching || hasRemainingItems || batchUpdateMutation.isLoading}
              />
              {selectedReplicas.length ? (
                <div className="tw-text-cool-gray-700 tw-font-semibold tw-text-sm">
                  {getIntlString('actions.selectedCount', { values: { count: selectedReplicas.length } })}
                </div>
              ) : null}
            </div>
            {dialog.visible ? (
              <TableReplicasWithLocationGroups
                modalLoaded={modalLoaded}
                dataSource={[selectedItem, ...(locationGroupsWithReplicasFiltered || [])]}
                isLoading={replicasListQuery.isFetching || batchUpdateMutation.isLoading || hasRemainingItems}
                selectAllOption={selectAllOption}
                allSelectedState={allSelectedState}
                selectedReplicas={selectedReplicas}
                replicasUpdateData={replicasUpdateData}
                toggleSelectReplica={toggleSelectReplica}
                toggleSelectAllReplica={toggleSelectAllReplica}
                toggleReplicaArchived={toggleReplicaArchived}
                handleUpdateReplicaData={handleUpdateReplicaData}
                hideTaxColumn={hideTaxColumn}
              />
            ) : null}
          </div>
        </div>
      </MarketModalFull>
      <ActionsModal
        dialog={actionDialog}
        handleUpdate={handleUpdate}
        actionData={actionData}
        setActionData={setActionData}
        onCancel={() => {
          actionDialog.dismiss();
        }}
        selectedItem={selectedItem}
        selectedReplicas={selectedReplicas}
        isLoading={batchUpdateMutation.isLoading}
        locationGroupsWithReplicasSorted={locationGroupsWithReplicasSorted as LocationGroupItem[]}
      />
      <CloseWithoutSavingModal
        leavePageModal={leavePageModal}
        countRef={updateCount}
        onClose={() => {
          hideSettingsDialog();
        }}
      />
    </>
  );
};

export const StoreSpecificDisplaySettingsModal: React.FC<React.PropsWithChildren<Props>> = (props) => (
  <I18nService prefix="menu.form.section.displaySettings.storeSpecific.modal">
    <StoreSpecificDisplaySettingsModalComponent {...props} />
  </I18nService>
);
