import { ReadLoyaltyUserStatusDto } from '@goparrot/loyalty-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { CUSTOMERS_QUERIES_ENUM } from '../enums';
import { loyaltyMerchantFacade } from '../facades';

export const useGetUserLoyaltyStatus = (
  merchantId: string,
  userId: string,
  options?: UseQueryOptions<ReadLoyaltyUserStatusDto>,
): UseQueryResult<ReadLoyaltyUserStatusDto> => {
  return useQuery(
    [CUSTOMERS_QUERIES_ENUM.GET_CUSTOMER_LOYALTY_STATUS, merchantId, userId],
    () => loyaltyMerchantFacade.userStatus(merchantId, userId),
    options,
  );
};
