import React, { useCallback } from 'react';
import { Button, GenericTable, IconArrowRightSmall, MarketIconDualRestore, MarketIconTrash, Spinner, TableCellText } from '@goparrot-dashboard/shared-ui';
import { useSortBy, useTable } from 'react-table';
import '../../style.scss';
import { getIntlCurrency, useI18n } from '@goparrot-dashboard/i18n';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { MENU_ELEMENTS_PLURAL } from '@goparrot-dashboard/shared-utils';
import { ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { getCentralLocation } from '@goparrot-dashboard/orchestration';
import { MarketCheckbox, MarketButton } from '@market/react-bindings';
import classNames from 'classnames';
import { getTaxPercentageValue, getStoreTitle } from '../../utils';
import { ORDERABILITY_VALUES, ReplicaUpdateDataFields, StoreSpecificItem } from './constants';
import { InputReplicaPrice } from './InputReplicaPrice';
import { SelectReplicaOrderable } from './SelectReplicaOrderable';
import { INIT_TABLE_DATA, LocationGroupItem, LocationGroupWithReplicasType, getTableItem, isLocationGroupItem, replicasCustomSort } from './utils';
import { InputReplicaTitle } from './InputReplicaTitle';
import { LocationGroupCheckbox } from './LocationGroupCheckbox';
import { DropdownItem } from './DropdownItem';
import { ReplicasUpdateData } from './StoreSpecificDisplaySettingsModal';

type Props = {
  dataSource: LocationGroupWithReplicasType[];
  isLoading: boolean;
  selectAllOption: () => void;
  allSelectedState: boolean | 'indeterminate';
  selectedReplicas: StoreSpecificItem[];
  replicasUpdateData: React.MutableRefObject<ReplicasUpdateData>;
  toggleSelectReplica: (replica: StoreSpecificItem) => void;
  toggleSelectAllReplica: (replica: StoreSpecificItem[]) => void;
  toggleReplicaArchived: (replica: StoreSpecificItem) => void;
  handleUpdateReplicaData: ({ uniqueName, data }: { uniqueName: string; data: ReplicaUpdateDataFields }) => void;
  hideTaxColumn: boolean;
  modalLoaded?: boolean;
};

export const TableReplicasWithLocationGroups: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({
    dataSource,
    isLoading,
    selectAllOption,
    allSelectedState,
    selectedReplicas,
    replicasUpdateData,
    toggleSelectReplica,
    toggleSelectAllReplica,
    toggleReplicaArchived,
    handleUpdateReplicaData,
    hideTaxColumn,
    modalLoaded = false,
  }) => {
    const { getIntlString } = useI18n();
    const {
      stores,
      merchant: { merchantId },
      franchiseLocations,
      isVirtualMenu,
    } = useStoreService();
    const getStoreName = React.useCallback((storeId: string) => getStoreTitle({ locations: stores, storeId, merchantId: merchantId, isVirtualMenu }), [
      stores,
      merchantId,
      isVirtualMenu,
    ]);
    const [openedItemsList, setOpenedItemsList] = React.useState<LocationGroupItem[]>([]);
    const toggleDropdown = React.useCallback(
      (original: LocationGroupItem) => {
        const foundItem = openedItemsList?.find((item) => item.locationGroupData.uuid === original.locationGroupData.uuid);
        if (foundItem) {
          const newState = openedItemsList?.filter((item) => item.locationGroupData.uuid !== foundItem.locationGroupData.uuid);
          return setOpenedItemsList(newState);
        }
        return setOpenedItemsList([...openedItemsList, original]);
      },
      [openedItemsList],
    );
    const centralLocation = getCentralLocation(franchiseLocations);
    const getIsCentralLocationItem = useCallback(
      (original: ReadStoreItemDto) => {
        return isVirtualMenu ? original.storeId === merchantId : original.storeId === centralLocation?.storeId;
      },
      [centralLocation?.storeId, isVirtualMenu, merchantId],
    );

    const customSort: any = React.useCallback((rowA: any, rowB: any, column) => {
      replicasCustomSort(rowA, rowB, column);
    }, []);
    const customSortMemo = React.useMemo(() => customSort, [customSort]);

    const sortTypes = {
      isOrderable: customSortMemo,
    };

    const handleChange = useCallback(
      ({ uniqueName, value, field }: any) => {
        handleUpdateReplicaData({ uniqueName, data: { [field]: value } });
      },
      [handleUpdateReplicaData],
    );

    const getReplicaIsSelected = useCallback(
      (replicaId: string) => {
        return selectedReplicas.some((item) => item.uniqueName === replicaId);
      },
      [selectedReplicas],
    );

    const columns = React.useMemo(
      () => [
        {
          Header: () => (
            <div className="tw-flex tw-align-center tw-justify-center tw-relative tw-pl-0.5 tw-pr-0.5">
              <MarketCheckbox
                indeterminate={allSelectedState === 'indeterminate' || undefined}
                checked={!!allSelectedState || undefined}
                onMarketCheckboxValueChange={selectAllOption}
              />
            </div>
          ),
          accessor: 'uniqueName',
          className: 'tw-w-0',
          disableSortBy: true,
          Cell: ({ row: { original } }: { row: { original: LocationGroupWithReplicasType } }) => {
            if (isLocationGroupItem(original)) {
              const item = original as LocationGroupItem;
              const isOpen = openedItemsList.find((item) => item.locationGroupData.uuid === item.locationGroupData.uuid);
              return (
                <div className="tw-flex tw-items-center tw-gap-5 tw-pl-5 tw-max-w-0 tw-pr-2">
                  <Button
                    onClick={() => toggleDropdown(item)}
                    size="small"
                    kind="clean"
                    color="accent"
                    rounded="full"
                    className="tw-px-0.5 tw-w-4 tw-h-4 tw-block"
                  >
                    <IconArrowRightSmall
                      width={12}
                      height={12}
                      className={classNames([isOpen ? ' tw-rotate-90' : 'tw-rotate-0', 'tw-transform tw-transition-all tw-text-light-blue-400'])}
                    />
                  </Button>
                  <LocationGroupCheckbox toggleSelectAllReplica={toggleSelectAllReplica} item={item} selectedReplicas={selectedReplicas} />
                </div>
              );
            }

            const item = original as ReadStoreItemDto;
            return getIsCentralLocationItem(item) ? (
              <div className="tw-w-7" />
            ) : (
              <div className="tw-flex tw-align-center tw-justify-center tw-relative tw-pr-2">
                <MarketCheckbox
                  checked={getReplicaIsSelected(item.uniqueName) || undefined}
                  onMarketCheckboxValueChange={() => {
                    toggleSelectReplica(item);
                  }}
                />
              </div>
            );
          },
        },
        {
          Header: getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.location', { isRootPath: true }),
          accessor: 'storeId',
          sorting: true,
          className: 'tw-w-2/12',
          sortType: 'basic',
          Cell: ({ value, row: { original } }: { value: string; row: { original: LocationGroupWithReplicasType } }) => {
            if (isLocationGroupItem(original)) {
              const item = original as LocationGroupItem;
              return (
                <TableCellText>
                  <div>
                    <span className="tw-pl-3 tw-w-max tw-text-blue-gray-900 tw-font-medium tw-text-sm tw-break-all">{item.locationGroupData.name}</span>
                  </div>
                </TableCellText>
              );
            }

            const item = original as ReadStoreItemDto;
            return (
              <TableCellText>
                <div>
                  <span className="tw-w-max tw-text-blue-gray-900 tw-font-medium tw-text-sm tw-break-all">{getStoreName(value)}</span>
                  {item.isArchived && (
                    <div className="tw-text-blue-gray-400 tw-text-xs tw-mt-1">
                      {getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.archivedItem', { isRootPath: true })}
                    </div>
                  )}
                </div>
              </TableCellText>
            );
          },
        },
        {
          Header: getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.title', { isRootPath: true }),
          sorting: true,
          accessor: 'title',
          className: 'tw-w-2/12',
          Cell: ({ row: { original } }: { row: { original: LocationGroupWithReplicasType } }) => {
            if (isLocationGroupItem(original)) {
              return null;
            }

            const item = getTableItem({ original: original as ReadStoreItemDto, replicasUpdateData });
            const value = item.title;

            return (
              <TableCellText>
                {getIsCentralLocationItem(item) ? (
                  <div>{value}</div>
                ) : (
                  <InputReplicaTitle initialValue={value} handleChange={(val) => handleChange({ uniqueName: item.uniqueName, value: val, field: 'title' })} />
                )}
              </TableCellText>
            );
          },
        },
        {
          Header: getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.orderability', { isRootPath: true }),
          sorting: true,
          sortType: 'isOrderable',
          accessor: 'isOrderable',
          className: 'tw-w-2/12',
          Cell: ({ row: { original } }: { row: { original: LocationGroupWithReplicasType } }) => {
            if (isLocationGroupItem(original)) {
              return null;
            }

            const item = getTableItem({ original: original as ReadStoreItemDto, replicasUpdateData });
            const value = item.isOrderable;

            return (
              <TableCellText>
                {getIsCentralLocationItem(item) ? (
                  <div>
                    {getIntlString(`menu.form.section.displaySettings.storeSpecific.modal.table.columns.orderability.${value ? 'orderable' : 'nonOrderable'}`, {
                      isRootPath: true,
                    })}
                  </div>
                ) : (
                  <SelectReplicaOrderable
                    initialValue={value}
                    disabled={item.isArchived}
                    handleChange={(val) => handleChange({ uniqueName: item.uniqueName, value: val === ORDERABILITY_VALUES.ORDERABLE, field: 'isOrderable' })}
                  />
                )}
              </TableCellText>
            );
          },
        },
        {
          Header: getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.price', { isRootPath: true }),
          accessor: 'price',
          sorting: true,
          className: 'tw-w-20',
          Cell: ({ row: { original } }: { row: { original: LocationGroupWithReplicasType } }) => {
            if (isLocationGroupItem(original)) {
              return null;
            }

            const item = getTableItem({ original: original as ReadStoreItemDto, replicasUpdateData });
            const value = item.price;

            return (
              <TableCellText>
                {getIsCentralLocationItem(item) ? (
                  <div className="tw-w-20">{getIntlCurrency(value)}</div>
                ) : (
                  <div className="tw-w-20">
                    <InputReplicaPrice
                      initialValue={value}
                      handleChange={(val) => handleChange({ uniqueName: item.uniqueName, value: val ?? 0, field: 'price' })}
                    />
                  </div>
                )}
              </TableCellText>
            );
          },
        },
        ...(hideTaxColumn
          ? []
          : [
              {
                Header: getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.tax', { isRootPath: true }),
                accessor: 'tax_percentage',
                disableSortBy: true,
                className: 'tw-w-1/12',
                Cell: ({ value, row: { original } }: { value: number; row: { original: LocationGroupWithReplicasType } }) => {
                  if (isLocationGroupItem(original)) {
                    return null;
                  }

                  return <TableCellText>{value ? <span>{`${getTaxPercentageValue(value)} %`}</span> : '-'}</TableCellText>;
                },
              },
            ]),
        {
          key: 'more',
          Header: '',
          accessor: 'isArchived',
          disableSortBy: true,
          className: 'tw-w-2/12',
          Cell: ({ value, row: { original } }: { value: number; row: { original: LocationGroupWithReplicasType } }) => {
            if (isLocationGroupItem(original)) {
              return null;
            }
            const item = original as ReadStoreItemDto;

            return getIsCentralLocationItem(item) ? null : (
              <div className="tw-flex tw-justify-center tw-items-center tw-cursor-pointer">
                <MarketButton variant={value ? 'regular' : 'destructive'} rank="tertiary" size="small" onClick={() => toggleReplicaArchived(item)}>
                  {value ? (
                    <>
                      <MarketIconDualRestore />
                      {getIntlString('button.restore', { isRootPath: true })}
                    </>
                  ) : (
                    <>
                      <MarketIconTrash />
                      {getIntlString('button.delete', { isRootPath: true })}
                    </>
                  )}
                </MarketButton>
              </div>
            );
          },
        },
      ],
      [
        getIntlString,
        hideTaxColumn,
        allSelectedState,
        selectAllOption,
        getIsCentralLocationItem,
        getReplicaIsSelected,
        openedItemsList,
        toggleSelectAllReplica,
        selectedReplicas,
        toggleDropdown,
        toggleSelectReplica,
        replicasUpdateData,
        getStoreName,
        handleChange,
        toggleReplicaArchived,
      ],
    );
    const tableProps = useTable({ columns, data: dataSource ?? INIT_TABLE_DATA, sortTypes }, useSortBy);

    return (
      <Spinner spinning={isLoading} className="tw-overflow-y-auto table-height">
        <GenericTable
          rowClassName="tw-bg-cool-gray-100"
          applyClassNameToRow={(row) => row?.index === 0}
          {...tableProps}
          modalLoaded={modalLoaded}
          isTableVirtualized={true}
          placeholder={getIntlString('menu.list.table.empty', { values: { itemType: MENU_ELEMENTS_PLURAL.CATEGORY }, isRootPath: true })}
          dropdownComponent={(original: LocationGroupWithReplicasType) => {
            if (!isLocationGroupItem(original)) {
              return null;
            }
            const item = original as LocationGroupItem;
            const isOpened = openedItemsList.some((el) => el.locationGroupData.uuid === item.locationGroupData.uuid);

            return (
              <DropdownItem
                original={item}
                isOpened={isOpened}
                replicasUpdateData={replicasUpdateData}
                selectedReplicas={selectedReplicas}
                toggleSelectReplica={toggleSelectReplica}
                toggleReplicaArchived={toggleReplicaArchived}
                handleUpdateReplicaData={handleUpdateReplicaData}
              />
            );
          }}
        />
      </Spinner>
    );
  },
);
