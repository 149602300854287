import { useEffect, useState, useCallback } from 'react';
import { IFlatValidationError } from '@goparrot/common';

import { campaignFacade } from '../../api/campaignApi';

export const useValidateCampaign = (
  merchantId: string,
  id: string,
): {
  errors: IFlatValidationError[];
  fetchValidation: () => void;
} => {
  const [errors, setErrors] = useState<IFlatValidationError[]>([]);
  const fetchValidation = useCallback(async () => {
    try {
      const data = await campaignFacade.validate(merchantId, id);
      if (data) {
        setErrors([]);
      }
    } catch (errors) {
      setErrors(errors.response.data.message);
    }
  }, [merchantId, id]);

  useEffect(() => {
    if (merchantId && id) {
      fetchValidation();
    }
  }, [fetchValidation, merchantId, id]);

  return { errors, fetchValidation };
};
