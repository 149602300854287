import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { loyaltyMerchantFacade } from '../facades';

type AdjustLoyaltyPointsParams = {
  merchantId: string;
  userId: string;
  points: number;
};

export const useAdjustLoyaltyPoints = (
  options?: UseMutationOptions<number, unknown, AdjustLoyaltyPointsParams>,
): UseMutationResult<number, unknown, AdjustLoyaltyPointsParams> => {
  return useMutation(({ merchantId, userId, points }) => loyaltyMerchantFacade.adjustPoints(merchantId, userId, points), options);
};
