import { CurrencyFullNameEnum, SupportedCurrencyEnum } from '@goparrot/common';
import { getIntlString } from '@goparrot-dashboard/i18n';

export enum ENTITY_VALIDATION_GROUPS {
  PUT = 'put',
  CREATE = 'create',
  PATCH = 'patch',
  CREATE_EMAIL = 'create_email',
  INVITE_EMAIL = 'invite_email',
  LOGIN_EMAIL = 'login_email',
  RESET_PASSWORD = 'reset_password',
  RESET_PASSWORD_CONFIRM = 'reset_password_confirm',
}

export enum WeekDayToKeyEnum {
  Monday = 'mon',
  Tuesday = 'tue',
  Wednesday = 'wed',
  Thursday = 'thu',
  Friday = 'fri',
  Saturday = 'sat',
  Sunday = 'sun',
}

export enum KeyToWeekDayEnum {
  mon = 'Monday',
  tue = 'Tuesday',
  wed = 'Wednesday',
  thu = 'Thursday',
  fri = 'Friday',
  sat = 'Saturday',
  sun = 'Sunday',
}

export const MIN_LOCATION_FOR_SEARCH = 6;

export const CREATE_GROUPS = [ENTITY_VALIDATION_GROUPS.CREATE];
export const UPDATE_GROUPS = [ENTITY_VALIDATION_GROUPS.PATCH];
export const PUT_GROUPS = [ENTITY_VALIDATION_GROUPS.PUT];

export const currencyOptions = Object.keys(SupportedCurrencyEnum).map((currency: string) => ({
  value: SupportedCurrencyEnum[currency as keyof typeof SupportedCurrencyEnum],
  label: CurrencyFullNameEnum[currency as keyof typeof CurrencyFullNameEnum],
}));

export const MEGABYTE = 1048576; // 1MB

export const NINE_AM = '09:00';
export const START_OF_DAY = '00:00';
export const END_OF_DAY = '23:59';

export const enum ReportTagEnum {
  MAIN = 'MAIN',
  FRANCHISE = 'FRANCHISE',
  CAMPAIGN = 'CAMPAIGN',
  CAMPAIGN_PROMO = 'CAMPAIGN_PROMO',
  COHORT = 'COHORT',
}

export const MENU_ELEMENTS_PLURAL = {
  ITEM_SET: getIntlString('menu.item.type.plural.itemSet'),
  ITEM_SET_CONCEPT: getIntlString('menu.item.type.plural.menuConcept'),
  STORE_ITEM_GROUP: getIntlString('menu.item.type.plural.storeItemGroup'),
  CATEGORY: getIntlString('menu.item.type.plural.category'),
  STORE_ITEM: getIntlString('menu.item.type.plural.storeItem'),
  ITEM_OPTIONS: getIntlString('menu.item.type.plural.itemOptions'),
  ITEM_BASE: getIntlString('menu.item.type.plural.itemBase'),
  ITEM_BASE_GROUP: getIntlString('menu.item.type.plural.itemBaseGroup'),
  TAXES: getIntlString('menu.item.type.plural.taxes'),
  VARIATION: getIntlString('menu.item.type.plural.variation'),
  VARIATION_GROUP: getIntlString('menu.item.type.plural.variationGroup'),
  COMBO_GROUP: getIntlString('menu.item.type.plural.comboGroup'),
  COMBOS: getIntlString('menu.item.type.plural.combo'),
};

export const PAGINATION = {
  skip: 0,
  take: 20000,
  countTotal: true,
};

export const PAGINATION_TAKE = 5000;

export const SITE_CONFIG = {
  siteName: 'GoParrot',
  footerText: `Goparrot ©${new Date().getFullYear()}`,
};

export const MM_SEARCH_CACHE_KEY = 'mm-search';

export const CURRENCY_SYMBOLS = {
  [SupportedCurrencyEnum.USD]: '$',
  [SupportedCurrencyEnum.AUD]: 'A$',
  [SupportedCurrencyEnum.CAD]: 'CA$',
};

export const SQUARE_ROUTE_BASE = 'https://squareup.com/dashboard/';
export const SQUARE_PAYMENT_URL = 'https://squareup.com/dashboard/account/pricing';

export const GRAFANA_REPORT_PATH_PREFIX = '/dashboard/franchise-reports/grafana-reports';

export const PUSH_NOTIFICATION_TEMPLATE_UUID = '70a1488d-89a1-48df-bde1-254a0e9705f2';
