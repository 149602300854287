import { useEffect, useState, useCallback } from 'react';

import { ReadCohortTemplateDto } from '@goparrot/cohort-processor-sdk';
import { cohortTemplateFacade } from '../../api/cohortTemplateApi';

export const useCohortTemplates = (): {
  cohortTemplates: ReadCohortTemplateDto[];
  isFetchingCohortTemplates: boolean;
  refetchCohortTemplates: () => void;
} => {
  const [cohortTemplates, setCohortTemplates] = useState<ReadCohortTemplateDto[]>();
  const [isFetchingCohortTemplates, setFetchingCohortTemplates] = useState<boolean>(false);
  const refetchCohortTemplates = useCallback(async () => {
    try {
      setFetchingCohortTemplates(true);
      const data = await cohortTemplateFacade.list();
      if (data) {
        setCohortTemplates(data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFetchingCohortTemplates(false);
    }
  }, []);

  useEffect(() => {
    refetchCohortTemplates();
  }, [refetchCohortTemplates]);

  return { cohortTemplates, isFetchingCohortTemplates, refetchCohortTemplates };
};
