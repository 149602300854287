import { PatchUserDto, ReadUserDto } from '@goparrot/core-sdk/platform/user';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { userFacade } from '../facades';

export type PatchUserParams = {
  userId: string;
  data: PatchUserDto;
};

export const useUpdateUser = (
  options?: UseMutationOptions<ReadUserDto, unknown, PatchUserParams>,
): UseMutationResult<ReadUserDto, unknown, PatchUserParams> => {
  return useMutation(({ userId, data }) => userFacade.patch(userId, data), options);
};
