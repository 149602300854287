import { ReadStoreDto, ReadDefaultStoreDto, MerchantFeaturesFlagsDto } from '@goparrot/store-v2-sdk';
import type { IReadRoleV2Dto, PermissionsV2ValueType } from '@goparrot/users-v2-sdk';
import { isDefaultStore } from '@goparrot-dashboard/shared-utils';
import { ReadVendorDto } from '@goparrot/vendor-sdk';
import { VendorProviderEnum } from '@goparrot/common';

import { checkForPermissions } from './checkForPermissions';

export const filterItemOnSidebar = ({
  item,
  roleV2,
  featuresFlags,
  selectedStore,
  vendorItem,
  arePushNotificationsEnabled,
}: {
  item: any;
  roleV2: IReadRoleV2Dto;
  featuresFlags: MerchantFeaturesFlagsDto;
  selectedStore?: ReadStoreDto | ReadDefaultStoreDto;
  vendorItem?: ReadVendorDto;
  arePushNotificationsEnabled?: boolean;
}) => {
  const vendorProvider = [VendorProviderEnum.GOPARROT, VendorProviderEnum.CHOWLY, VendorProviderEnum.CLOVER, VendorProviderEnum.OTTER];
  const hasPermissions = (permissions: PermissionsV2ValueType[]) => {
    if (!Array.isArray(permissions)) {
      return;
    }

    return permissions?.some((permission) => checkForPermissions(permission, roleV2));
  };

  const notRender = [
    item.permissions && !hasPermissions(item.permissions),
    false === item.defaultStore && selectedStore && isDefaultStore(selectedStore),
    true === item.utility,
    vendorProvider.find((item) => item === vendorItem?.provider) && item.isSquareOrToastPOS === true,
    featuresFlags && 'featuresFlagsCheck' in item && !item?.featuresFlagsCheck(featuresFlags),
    item.pushNotificationsCheck && !arePushNotificationsEnabled,
  ];

  return !notRender.includes(true);
};
