import { SearchResultsDto, SearchUserDto } from '@goparrot/core-sdk/platform/user';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { CUSTOMERS_QUERIES_ENUM } from '../enums';
import { userFacade } from '../facades';

export const useSearchUsers = (
  params: SearchUserDto,
  offset?: number,
  limit?: number,
  options?: UseQueryOptions<SearchResultsDto>,
): UseQueryResult<SearchResultsDto> => {
  return useQuery([CUSTOMERS_QUERIES_ENUM.SEARCH_CUSTOMERS, params, offset, limit], () => userFacade.search(params, offset, limit), options);
};
