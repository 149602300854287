import { PatchConfigurationDto, ReadConfigurationDto } from '@goparrot/notifier-sdk';
import { useState, useCallback } from 'react';

import { notifierConfigurationFacade } from '../../api/notifierConfigurationApi';
// After campaign refactoring will be moved in api module
export const useUpdateConfig = (): {
  config: ReadConfigurationDto;
  isConfigFetching: boolean;
  fetchUpdateConfig: (merchantId: string, uuid: string, data: PatchConfigurationDto) => void;
} => {
  const [config, setConfig] = useState<ReadConfigurationDto>();
  const [isConfigFetching, setConfigFetching] = useState<boolean>(false);
  const fetchUpdateConfig = useCallback(async (merchantId: string, uuid: string, data: PatchConfigurationDto) => {
    try {
      setConfigFetching(true);
      const config = await notifierConfigurationFacade.patch(merchantId, uuid, data);
      if (config) {
        setConfig(config);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setConfigFetching(false);
    }
  }, []);

  return { config, isConfigFetching, fetchUpdateConfig };
};
